import { useEffect, useRef, useState } from "react";
import { AppFieldsContainer, Application, Context, Value } from "../../../types/types"
import DependencyTextField from "../DependencyTextField";
import { get } from "../../../util/Axios";
import VersionPicker from "../../Pickers/VersionPicker";
import { Button } from "react-bootstrap";
import { useCommonConfig } from "../../Hooks/Hooks";
import { toast } from 'react-toastify';

export interface AppvaluesDetailProps {
    isSimpleMode: boolean,
    application: Application,
    nextPage: Function,
    context: Context,
    preventLatestVersion?: boolean,
}

function AppValuesDetail(props: AppvaluesDetailProps) {
    const [appVersion, setAppVersion] = useState<string>('')
    const [appFields, setAppFields] = useState<AppFieldsContainer>({important: [], other: []})
    const [values, setValues] = useState<Value[]>([])
    const [validFieldNames, setValidFields] = useState<string[]>([])
    const [optionalFields, setOptionalFields] = useState<string[]>([])
    const [secretFields, setSecretFields] = useState<string[]>([])
    const [buttonsActive, setButtonsActive] = useState(false)

    const [errorMessage, setErrorMessage] = useState("")

    const { data: commonConfigValues } = useCommonConfig(props.application.moduleData.module.id, appVersion)

    const formRef = useRef(null);

    useEffect(() => {
        setAppVersion(props.application.moduleData.version)
        setValues(props.application.moduleData.values)
    }, [])

    useEffect(() => {
        if (!appVersion) return
        getValueFields()
        get(`/technical/templates/${props.context?.id}?module_id=${props.application.moduleData.module.id}&version=${appVersion}`).then(response => {
            if (response.status !== 200) {
                toast.error(`Error checking available Templates ${response.status}: ${response.data}`)
                return;
            }
            const data = response.data.templates
            if (data.length === 0) {
                setErrorMessage("There are no Templates available for this version!")
            } else {
                setErrorMessage("")
            }
        }).catch(error => {
            toast.error(`Error checking available Templates ${error.response.status}: ${error.response.data}`)
          });
    }, [commonConfigValues])

    const getValueFields = async () => {
        if (!commonConfigValues) return;

        const appFields: AppFieldsContainer = {important: commonConfigValues.important, other: commonConfigValues.other}

        const values: Value[] = []
        const validFieldNames: string[] = []    
        const optionalValues: string[] = []
        const secretFields: string[] = []
        const valuesFromApplication = props.application.moduleData.values;
        Object.keys(appFields).forEach(importance => {
            appFields[importance as keyof AppFieldsContainer].forEach(field => {
                if (valuesFromApplication.length === 0) {
                    values.push({name: field.name, value: field.value, is_secret: field.is_secret})
                } else {
                    const existingValue = valuesFromApplication.find(value => {
                        return value.name === field.name
                    })
                    values.push(existingValue ?? {name: field.name, value: field.value})
                }

                if (field.optional) {
                    optionalValues.push(field.name)
                }
                if (field.is_secret) {
                    secretFields.push(field.name)
                }
                validFieldNames.push(field.name)
            })
        })
        if (values.length > 0) {
            setValues(values)
        }

        setOptionalFields(optionalValues)
        setValidFields(validFieldNames)
        setSecretFields(secretFields)
        setAppFields(appFields)
        setButtonsActive(true)
    }

    const onSubmit = (event: any) => {
        event.preventDefault();

        const values: Value[] = []
        const formData = new FormData(formRef.current!)

        let valid = true
        for (let pair of formData.entries()) { 
            const [env_var, value] = pair

            if (!validFieldNames.includes(env_var)) continue;

            if (value.toString().length === 0 && !optionalFields.includes(env_var)) {
                valid = false
                return
            }

            const is_secret = secretFields.includes(env_var)

            values.push({name: env_var, value, is_secret} as Value)
        }

        if (!valid) return

        props.application.moduleData.values = values
        props.application.moduleData.version = appVersion
        props.nextPage()
    }

    const renderFields = () => {
        return Object.keys(appFields).map((importance: string) => {
            const fields = appFields[importance as keyof AppFieldsContainer].map(field => {
                return (
                    <DependencyTextField
                        key={`depField-${field.name}`}
                        setValue={(key: string, value: any) => {
                            const indexOf = values.indexOf(values.find((value: Value) => value.name === key)!)
                            if (indexOf > -1) {
                                const copy = values.slice()
                                copy[indexOf] = {name: key, value}
                                setValues(copy)
                            } else {
                                setValues(oldValues => [...oldValues, {name: key, value}])
                            }
                        }}
                        getValue={(key: string) => {
                            const valInValues = values.find((value: Value) => value.name === key)
                            return valInValues ? valInValues.value : ''
                        }}
                        field={field}
                        values={values}
                        type={field.type}
                        deactivated={props.isSimpleMode && field.value.length > 0}
                    />
                )
            })

            return (
                <div key={importance} className="textFieldDiv fullWidth">
                    <label className="bold">{importance}</label>
                    {fields}
                </div>
            )
        })
    }
    
      return (
        <div>
            <div style={{color: 'red'}}>
                    {errorMessage.length > 0 && <span>
                        {errorMessage}
                    </span>}
                </div>
            <div>
                <VersionPicker
                    module={props.application.moduleData.module}
                    editable={true}
                    useLatest={props.preventLatestVersion ? false : true}
                    selectedVersion={appVersion}
                    setSelectedVersion={setAppVersion}
                />
            </div>
            <div>
                <form onSubmit={onSubmit} ref={formRef}>
                    {renderFields()}
                    <div style={{display: 'flex', gap: '10px'}}>
                        <Button disabled={!buttonsActive} type="submit">Next</Button>
                    </div>
                </form>
            </div>
        </div>
      )
}

export default AppValuesDetail;