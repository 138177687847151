import { useState } from "react"
import { Context } from "../../../types/types"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import ContextEditModal from "./ContextEditModal"
import { toast } from "react-toastify"
import { useContexts, useCreateContext, useDeleteContext, useUpdateContext } from "../../Hooks/Hooks"

export interface ContextEditorProps {
    context: Context | undefined,
    setContext: Function,
    updateContextPicker: Function
}

function ContextEditor(props: ContextEditorProps) {

    const { data } = useContexts()
    const create = useCreateContext()
    const update = useUpdateContext()
    const remove = useDeleteContext()

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<Context[]>([])
    const [editField, setFieldToEdit] = useState<Context | undefined>(undefined)

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Name",
            width: 200,
        },
        {
            field: 'urls',
            headerName: "Domain Urls",
            width: 300,
        },
        {
            field: 'ips',
            headerName: 'IPs',
            widt: 200,
        },
        {
            field: 'db_ressource_group_name',
            headerName: "DB Ressource Group Name",
            width: 300,
        },
        {
            field: 'misc_ressource_group_name',
            headerName: "Misc. Ressource Group Name",
            width: 300,
        },
        {
            field: 'repoUrl',
            headerName: "Repo URL",
            width: 220,
        },
        {
            field: 'color',
            headerName: "Color",
            width: 100,
            renderCell: (params: any) => {
                return (
                    <div style={{height: '90%', aspectRatio: '1/1', backgroundColor: `${params.row.color}`}} />
                )
            }
        },
        {
            field: 'git_folder_path',
            headerName: 'Git Folder Path',
            width: 200
        },
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: Context) => {
            if (field.id === props.context?.id) {
                return toast.error("Can't delete current Context!")
             }
            remove.mutateAsync(field.id).then(response => {
                if (response.status !== 200) return
                props.updateContextPicker()
            })
        })
    }

    const saveField = (field: Context, createNew?: boolean) => {
        if (createNew) {
            create.mutate(field)
        } else {
            update.mutateAsync(field).then(response => {
                if (response.status !== 200) return;
                if (props.context?.id === editField!.id) {
                    props.setContext(field)
                }
                setSelectedRows([field])
                setFieldToEdit(undefined)
                props.updateContextPicker()
            })
        }
    }

    return (
        <div>
            <ContextEditModal
                open={modalOpen}
                cancel={() => {
                    setOpenModal(false)
                    setFieldToEdit(undefined)
                }}
                submit={(name: string, urls: string[], color: string, dbResGroup: string, miscResGroup: string, repoUrl: string, git_folder_path: string, ips: string[]) => {
                    const field: Context = {id: '', color, name, urls, db_ressource_group_name: dbResGroup, misc_ressource_group_name: miscResGroup, repoUrl, git_folder_path, ips}
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
            </div>
            <div>
                <Box sx={{ height: 550, width: '100%' }}>
                    <DataGrid
                        rows={data ?? []} 
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = data?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default ContextEditor