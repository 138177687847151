import { useEffect, useState } from "react";
import { Application, Context, GeneralTenantInformation, Module, TechnicalTemplateRecord, TenantDetailMode, TenantInformation, Tier, Value, CustomerConfigData, TechnicalCustomerConfigData, AppOfAppsTemplate, Tag, StringWrapper, ExistingTenantSecretRessources, ExistingTenantDbRessources } from "../../../types/types";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import BasicTenantInfo from "./BasicTenantInfo";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { get, post, put } from "../../../util/Axios";
import { useNavigate, useParams } from "react-router-dom";
import ApplicationModal from "../../ApplicationEditor/ApplicationModal";
import StringValueEditor from "./StringValueEditor";
import DomainPicker from "../../Pickers/DomainPicker";
import IpPicker from "../../Pickers/IpPicker";
import AppOfAppsTemplPicker from "../../Pickers/AppOfAppsTemplPicker";
import { toast } from 'react-toastify';
import TagMultiSelect from "../../Pickers/TagMultiSelect";
import TenantTemplatePreviewModal from "./TenantTemplatePreviewModal";
import ModuleVersionTagPicker from "../../Pickers/ModuleVersionTagPicker";

export interface TenantDetailProps {
    context: Context | undefined,
    versionOverride?: StringWrapper,
    templOverride?: StringWrapper,
    moduleVersionTagOverride?: StringWrapper,
    setContextPickerAvailable?: Function,
    tenantId?: string,
    backBtnCallback?: Function,
    updateBtnCallback?: Function,
    mode: TenantDetailMode,
}

function TenantDetail(props: TenantDetailProps) {

    const [generalInfo, setGeneralInfo] = useState<GeneralTenantInformation>({CustomerName: '', CustomerShortName: '', CustomerContactName: '', CustomerEmail: ''})
    const [tier, setTier] = useState<Tier>();
    const [applications, setApplications] = useState<Application[]>([])
    const [existingSecretRessources, setExistingSecretRessources] = useState<ExistingTenantSecretRessources>({api_user_login: '', api_user_pw: '', jwt: ''})
    const [existingDbRessources, setExistingDbRessources] = useState<ExistingTenantDbRessources>({db_admin_login: '', db_admin_pw: '', db_host: '', db_name: '', db_user_login: '', db_user_pw: ''})
    const [domain, setDomain] = useState("")
    const [ip, setIp] = useState("")
    const [prevent_delete, setPreventDelete] = useState(false)
    const [tenantDbInfo, setTenantDbInfo] = useState({db_name: "", db_host: ""})
    const [selectedTags, setSelectedTags] = useState<Tag[]>([])
    const [convertOldDNS, setConvertOldDNS] = useState(false)
    const [moduleTags, setModuleTags] = useState<{[module_id: string]: string}>({})

    const [availableModules, setAvailableModules] = useState<Module[]>([]);

    const [selectedtedApplication, setSelectedApplication] = useState<Application | undefined>(undefined);
    const [isOpenEditor, setOpenEditor] = useState(false)

    const [openTemplatePreview, setOpenTemplatePreview] = useState(false)

    const [forceGeneralInfoOverwrite, setGeneralInfoOverwrite] = useState(false)
    const [buttonsDisabled, setButtonsDisabled] = useState(false)
    const [doRessourcesExist, setRessourcesExist] = useState(false)
    const [doSecretRessourcesExist, setSecretRessourcesExist] = useState(false)

    const [appofappsTemplate, setAppofappsTemplate] = useState<AppOfAppsTemplate>()
    const [aoaTemplVersionOverride, setAoaTemplVersionOverride] = useState("")

    const params = useParams()
    let tenantId = props.tenantId ? props.tenantId : params.tenantId;

    const navigate = useNavigate()

    useEffect(() => {
        fetchFields()
        props.setContextPickerAvailable?.(false)
    }, [])

    useEffect(() => {
        if (props.mode === TenantDetailMode.create) return;
        fetchFields()
    }, [props.tenantId])

    const fetchFields = async () => {
        let tenantData: TenantInformation
        let tenantApps: Application[] = applications

        const moduleListResponse = await get('/modules')
            
        if (moduleListResponse.status !== 200) {
            return
        }

        if (tenantId) {
            const tenantDataResponse: any = await get(`/contexts/${props.context?.id}/tenants/${tenantId}`).catch(err => {
                return toast.error(`Error: ${err.message}: ${err.response.data}`)
            })
            if (tenantDataResponse.status !== 200) {
                return toast.error(`Error ${tenantDataResponse.status}: ${tenantDataResponse.data}`)
            }

            const customerDataResponse: any = await get(`/customer-config/tenants/${tenantId}`).catch(err => {
                return toast.error(`Error: ${err.message}: ${err.response.data}`)
            })
            if (customerDataResponse.status !== 200) {
                return toast.error(`Error ${customerDataResponse.status}: ${customerDataResponse.data}`)
            }

            const techCustomerDataResponse: any = await get(`/technical-customer-config/tenants/${tenantId}`).catch(err => {
                return toast.error(`Error: ${err.message}: ${err.response.data}`)
            })
            if (techCustomerDataResponse.status !== 200) {
                return toast.error(`Error ${techCustomerDataResponse.status}: ${techCustomerDataResponse.data}`)
            }

            tenantData = tenantDataResponse.data;
            const customerData: CustomerConfigData = customerDataResponse.data
            const techCustomerData: TechnicalCustomerConfigData = techCustomerDataResponse.data

            const { contact_email,
                contact_name,
                name,
                shortname } = tenantData!
            setGeneralInfo({CustomerContactName: contact_name, CustomerEmail: contact_email, CustomerName: name, CustomerShortName: shortname})
            setGeneralInfoOverwrite(!forceGeneralInfoOverwrite)
            setAoaTemplVersionOverride(tenantData.aoa_template_id)
            setPreventDelete(tenantData.prevent_delete)
            setTier(tenantData.tier)
            setIp(tenantData.ip)
            setTenantDbInfo({db_name: tenantData.db_name, db_host: tenantData.db_host})
            setSelectedTags(tenantData.tags)

            const moduleTags: StringWrapper = {}
            Object.keys(tenantData.module_tags).forEach((moduleId: string) => {
                if (!props.moduleVersionTagOverride || !(moduleId in props.moduleVersionTagOverride)) {
                    moduleTags[moduleId] = tenantData.module_tags[moduleId]
                } else {
                    moduleTags[moduleId] = props.moduleVersionTagOverride[moduleId]
                }
            })
            setModuleTags(moduleTags)
            
            tenantApps = []
            Object.keys(tenantData!.versions).forEach(moduleID => {
                const version = tenantData!.versions[moduleID]
                const module = moduleListResponse.data.find((module: Module) => `${module.id}` === moduleID)
                if (!module) return

                const values: Value[] = []
                customerData[moduleID].forEach((value) => {
                    values.push({name: value.env_var, value: value.value, is_secret: value.is_secret})
                })

                const template = techCustomerData[moduleID].template
                const templValues = techCustomerData[moduleID].values

                const application: Application = {moduleData: {module, version, values},
                                                templateData: {template, values: templValues }, checked: props.mode === TenantDetailMode.update ? false : true}
                tenantApps.push(application)
            })

            if (props.versionOverride) {
                tenantApps.forEach(app => {
                    app.moduleData.version = props.versionOverride![app.moduleData.module.name] ?? app.moduleData.version
                })
            }

            setApplications(tenantApps)
        }

        const modules: Module[] = moduleListResponse.data
        const availableModules: Module[] = []

        modules.forEach(module => {
            if (props.mode === TenantDetailMode.update) return;
            
            if (tenantApps.find(app => app.moduleData.module.id === module.id)) {
                return
            }

            if (module.required) addEmptyApplication(module)
            else availableModules.push(module)
        })

        setAvailableModules(availableModules)
    }

    const onSubmit = () => {
        setButtonsDisabled(true)
        let valid = true;

        const values: {[key: string]: {[key: string]: {value: string, is_secret: boolean}}} = {}
        const templateValues: {[key: string]: {[key: string]: {value: string}}} = {}
        const versions: {[key: string]: string} = {}
        const templates: TechnicalTemplateRecord = {}

        const generalValues: {[key:string]: string} = {}
        Object.keys(generalInfo).forEach(infoField => {
            const value = generalInfo[infoField as keyof GeneralTenantInformation]
            if (value.length === 0 || value === '') {
                toast.error(`Missing Value ${infoField} in general values`)
                valid = false;
                return;
            }
            generalValues[infoField] = value
        })

        if (domain.length === 0) {
            toast.error(`Domain missing`)
            valid = false
        }

        if (!appofappsTemplate) {
            toast.error("Please select an app of apps template!")
            return
        }

        if (!valid) {
            setButtonsDisabled(false)   
            return;
        }

        applications.forEach(application => {
            if (!application.checked) {
                toast.error(`Please make sure you check Module ${application.moduleData.module.name} to make sure all values are fine!`)
                valid = false;
                return;
            }

            if (application.moduleData.version.length === 0) {
                toast.error(`Missing Version for Module ${application.moduleData.module.name}. Please submit Module again`)
                valid = false;
                return;
            }

            if (application.moduleData.values.length === 0) {
                toast.error(`Missing Values for Module ${application.moduleData.module.name}`)
                valid = false;
                return;
            }

            if (application.templateData.values.length === 0) {
                toast.error(`Missing Template values for Module ${application.moduleData.module.name}`)
                valid = false;
                return;
            }

            if (!(application.moduleData.module.id in moduleTags) || moduleTags[application.moduleData.module.id].length === 0) {
                toast.error(`Module ${application.moduleData.module.name} has no tag set!`)
                valid = false;
                return
            }

            const appName = application.moduleData.module.name
            const appValues: {[key: string]: {value: string, is_secret: boolean}} = {}
            application.moduleData.values.forEach(value => {
                appValues[value.name] = {value: value.value, is_secret: value.is_secret ?? false}
            })

            const templValues: {[key: string]: {value: string}} = {}
            application.templateData.values.forEach(value => {
                if (value.value === '' || value.value.length === 0) {
                    toast.error(`Template value ${value.name} for ${application.moduleData.module.name} must not be empty!`)
                    valid = false;
                    return;
                }

                templValues[value.name] = {value: value.value}
            })

            values[appName] = appValues
            templateValues[appName] = templValues
            versions[appName] = application.moduleData.version;
            templates[appName] = application.templateData.template;
        })

        if (doRessourcesExist) {
            Object.keys(existingDbRessources).forEach((key) => {
                const value = existingDbRessources[key as keyof ExistingTenantDbRessources]
                if (value.length === 0) {
                    toast.error(`Existing Ressources Value ${key} cannot be empty!`)
                    valid = false;
                    return;
                }
            })
        }

        if (doSecretRessourcesExist) {
            Object.keys(existingSecretRessources).forEach((key) => {
                const value = existingSecretRessources[key as keyof ExistingTenantSecretRessources]
                if (value.length === 0) {
                    toast.error(`Existing Ressources Value ${key} cannot be empty!`)
                    valid = false;
                    return;
                }
            })
        }

        if (props.mode !== TenantDetailMode.create) {
            if (tenantDbInfo.db_host.length === 0 || tenantDbInfo.db_name.length === 0) {
                toast.error("DB Host and Name must be set!")
                valid = false
            }
        }

        if (!valid) {
            setButtonsDisabled(false)
            return;
        }

        let existingRessourcesBody: any = doRessourcesExist ? existingDbRessources : {}
        existingRessourcesBody['update_old_dns'] = convertOldDNS
        existingRessourcesBody['existingSecretValues'] = false
        if (doSecretRessourcesExist) {
            existingRessourcesBody['existingSecretValues'] = true
            existingRessourcesBody = {...existingRessourcesBody, ...existingSecretRessources}
        }

        if (props.mode === TenantDetailMode.create) {
            post(`/contexts/${props.context?.id}/tenants/`, {values: generalValues, tier, domain, ip, aoa_template: appofappsTemplate, prevent_delete, tags: selectedTags, module_tags: moduleTags, is_demo: false}).then(async response => {
                if (response.status !== 201) {
                    toast.error(`Tenant not created: Code ${response.status}`)
                    setButtonsDisabled(false)
                    return
                }

                const tenant_id = response.data

                await post(`/customer-config/batch`, {tenant_id: tenant_id, values, versions}).catch(err => {
                    toast.error(`Error creating customer config: ${err.message}: ${err.response.data}`)
                })
                await post(`/technical-customer-config/batch`, {tenant_id, templates, values: templateValues}).catch(err => {
                    toast.error(`Error creating techncial customer config: ${err.message}: ${err.response.data}`)
                })

                const body = {
                    tenant_id, 
                    location: 'westeurope', 
                    db_server_version: '8.0.21',
                    creating_existing: doRessourcesExist,
                    existingResourceValues: existingRessourcesBody
                }

                post(`/contexts/${props.context?.id}/crud/mandantenerstellung`, body).then(response => {
                    if (response.status !== 200) {
                        toast.error(`Tenant Mandantenerstellung not started. Code: ${response.status}`)
                        setButtonsDisabled(false)
                        return;
                    }
                    
                    toast.success("Mandantenerstellung started")
                    navigate('/tenants')


                }).catch(err => {
                    setButtonsDisabled(false)
                    toast.error(`Error starting Mandantenerstellung: ${err.message}: ${err.response.data}`)
                })
            }).catch(err => {
                setButtonsDisabled(false)
                toast.error(`${err.message}: ${err.response.data}`)
            }) 
        }
        else {
            put(`/contexts/${props.context?.id}/tenants/${tenantId}`, {values: generalValues, tier, domain, ip, aoa_template: appofappsTemplate, module_tags: moduleTags, prevent_delete, db_name: tenantDbInfo.db_name, db_host: tenantDbInfo.db_host, tags: selectedTags, is_demo: false}).then(async response => {
                if (response.status !== 200) {
                    toast.error(`Tenant not edited: Code ${response.status}`)
                    setButtonsDisabled(false)
                    return
                }
                await post(`/customer-config/batch?deleteOld=true`, {tenant_id: tenantId, values, versions}).catch(err => {
                    toast.error(`Error creating customer config: ${err.message}: ${err.response.data}`)
                })
                await post(`/technical-customer-config/batch?deleteOld=true`, {tenant_id: tenantId, templates, values: templateValues}).catch(err => {
                    toast.error(`Error creating techncial customer config: ${err.message}: ${err.response.data}`)
                })

                post(`/contexts/${props.context?.id}/crud/mandantenupdate`, {tenant_id:  tenantId}).then(response => {
                    if (response.status !== 200) {
                        toast.error(`Tenant Mandantenupdate not started. Code: ${response.status}`)
                        setButtonsDisabled(false)
                        return;
                    }
                    
                    toast.success("Mandantenupdate started")
                    if (props.mode === TenantDetailMode.edit) {
                        props.setContextPickerAvailable?.(true)
                        navigate('/tenants')
                    } else {
                        setButtonsDisabled(false)
                        props.updateBtnCallback!()
                    }

                }).catch(err => {
                    setButtonsDisabled(false)
                    toast.error(`Error starting Mandantenupdate: ${err.message}: ${err.response.data}`)
                })
            }).catch(err => {
                setButtonsDisabled(false)
                toast.error(`${err.message}: ${err.response.data}`)
            }) 
        }
    }

    const addEmptyApplication = (module: Module) => {
        const application: Application = {moduleData: {module: module, values: [], version: ''}, templateData: {template: {app: module.name, contextId: props.context!.id, id: '', template: '', version: '', compatible_app_versions: []}, values: []}, checked: false}
        
        setApplications(oldApps => [...oldApps, application])
        if (availableModules.includes(module)) {
            availableModules.splice(availableModules.indexOf(module), 1)
            setAvailableModules(availableModules)
        }
    }

    const removeApplication = (application: Application) => {
        if (application.moduleData.module.required) return

        const copy = applications.slice()
        copy.splice(applications.indexOf(application), 1)
        setApplications(copy)
        setAvailableModules(oldModules => [...oldModules, application.moduleData.module])
    }

    const invalidateApplications = () => {
        const currApps = applications
        currApps.forEach(app => app.checked = false)
        setApplications(currApps)
    }

    const columns = [
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
          valueGetter: (value: any) => value.row?.moduleData.module.name
        },
        {
            field: "Version Tag",
            headerName: "Version Tag",
            width: 150,
            renderCell: (params: any) => {
                const moduleId = params.row.moduleData.module.id
                return (
                    <div style={{ width: '100px'}}>
                        <ModuleVersionTagPicker
                            module={params.row.moduleData.module as Module}
                            context={props.context}
                            setTag={(tag: string) => {
                                setModuleTags(old => {
                                    return {...old, [moduleId]: tag}
                                })
                            }}
                            filter={props.moduleVersionTagOverride ? props.moduleVersionTagOverride[moduleId] : undefined}
                            tag={moduleTags[moduleId] ?? ""}
                            hideLabel={true}
                            preventDefault={props.mode !== TenantDetailMode.create}
                            small={true}
                        />
                    </div>
                )
            }
        },
        {
            field: 'module',
            headerName: "Module Version",
            width: 150,
            valueGetter: (value: any) => value.row?.moduleData.version
        },
        {
            field: 'template',
            headerName: "Template Version",
            width: 150,
            valueGetter: (value: any) => value.row?.templateData.template.version
        },
        {
            field: 'checked',
            headerName: "Needs Checking?",
            width: 150,
            valueGetter: (value: any) => !value.row?.checked
        },
        {
            field: "Actions",
            headerName: "Actions",
            width: 250,
            renderCell: (params: any) => {
                return (
                    <div style={{ display: 'flex', gap: '10px'}}>
                        <Button 
                            onClick={() => openEditor(params.row as Application)}
                        >
                            Edit
                        </Button>
                        {props.mode !== TenantDetailMode.update &&
                            <Button 
                                disabled={params.row.moduleData.module.required} 
                                onClick={() => removeApplication(params.row as Application)}
                            >
                                Remove
                            </Button>
                        }
                    </div>
                )
            }
        }
      ];

      const openEditor = (application: Application) => {
        setSelectedApplication(application)
        application.checked = false
        setOpenEditor(true)
      }

      const renderDropdownChildren = () => {
            return availableModules.map(module => {
                return (
                    <Dropdown.Item key={module.id} onClick={() => {
                        addEmptyApplication(module)
                    }}>
                        {module.name}
                    </Dropdown.Item>
                )
            })
    }

    const goBack = () => {
        if (props.mode === TenantDetailMode.update) {
            return props.backBtnCallback?.()
        }
        if (props.mode === TenantDetailMode.edit) {
            props.setContextPickerAvailable?.(true)
        }
        navigate('/tenants')
    }

    return (
        <div>
            <TenantTemplatePreviewModal
                applications={applications}
                tenantInfo={generalInfo}
                appOfAppsTemplate={appofappsTemplate}
                dbInfo={tenantDbInfo}
                tags={selectedTags}
                moduleVersionTags={moduleTags}
                domain={domain}
                cancel={() => {setOpenTemplatePreview(false)}}
                open={openTemplatePreview}
            />
            <ApplicationModal
                application={selectedtedApplication!}
                tier={tier!}
                context={props.context!}
                templOverride={props.templOverride}
                open={isOpenEditor}
                cancel={() => {
                    setSelectedApplication(undefined)
                    setOpenEditor(false)
                }}
                close={() => {
                    setSelectedApplication(undefined)
                    setOpenEditor(false)
                }}
            />
            <div style={{width: '100%', marginTop: '10px'}}>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button onClick={goBack}>Cancel</Button>
                    <Button disabled={buttonsDisabled} onClick={onSubmit}>Save</Button>
                    <Button disabled={buttonsDisabled} onClick={() => {
                        setOpenTemplatePreview(true)
                    }}>Template Preview</Button>
                    <DomainPicker
                        context={props.context}
                        domain={domain}
                        label="Domain"
                        setDomain={setDomain}
                    />
                    <IpPicker
                        context={props.context}
                        ip={ip}
                        label='IP'
                        setIp={setIp}
                    />
                    <AppOfAppsTemplPicker
                        setSelectedTemplate={setAppofappsTemplate}
                        templ={appofappsTemplate}
                        key={"Aoa_template_picker"}
                        small={true}
                        defaultIdOverride={aoaTemplVersionOverride}
                    />
                    <TagMultiSelect
                        selected={selectedTags}
                        setSelected={setSelectedTags}
                        width={'15%'}
                        />
                </div>
                <div style={{display: 'flex', gap: '15px'}}>
                    <div style={{marginBottom: '10px'}}>
                        <label className="bold underlined">General Info</label>
                        <BasicTenantInfo
                            context={props.context}
                            generalInfo={generalInfo}
                            setGeneralInfo={setGeneralInfo}
                            detailMode={props.mode}
                            tier={tier}
                            setTier={(newTier: Tier) => {
                                setTier(newTier);
                                //Prevents app invalidation if tier is set by fetching tenant data
                                //for the first time in edit mode
                                if (tier?.id === newTier.id) return;
                                //Since Template Values might change with the tier
                                //Force user to check applications again
                                invalidateApplications()
                            }}
                            preventDelete={prevent_delete}
                            setPreventDelete={setPreventDelete}
                            forceOverwrite={forceGeneralInfoOverwrite}
                        />
                    </div>
                    {props.mode === TenantDetailMode.create && <div className="textFieldDiv fullWidth">
                        <label className="bold underlined">Existing Tenant?</label> 
                        <input type="checkbox" checked={doRessourcesExist} onChange={(e) => {
                            setRessourcesExist(e.target.checked)
                        }}/>
                        {doRessourcesExist && <div>
                            <StringValueEditor
                                setValues={setExistingDbRessources}
                                values={existingDbRessources}
                                required={true}
                            />
                            <div className="textFieldDiv fullWidth">
                                <label className="bold underlined">Existing Secrets?</label> 
                                <input type="checkbox" checked={doSecretRessourcesExist} onChange={(e) => {
                                    setSecretRessourcesExist(e.target.checked)
                                }}/>
                                {doSecretRessourcesExist &&
                                    <StringValueEditor
                                        setValues={setExistingSecretRessources}
                                        values={existingSecretRessources}
                                        required={doSecretRessourcesExist}
                                    />
                                }
                            </div>
                            <div className="textFieldDiv fullWidth">
                                <label className="">Convert CNAMEs?</label> 
                                <input type="checkbox" checked={convertOldDNS} onChange={(e) => {
                                    setConvertOldDNS(e.target.checked)
                                }}/>
                            </div>
                        </div>}
                    </div>}
                    {props.mode !== TenantDetailMode.create && <div className="textFieldDiv fullWidth">
                        <StringValueEditor
                            setValues={setTenantDbInfo}
                            values={tenantDbInfo}
                            required={true}
                        />
                    </div>}
                </div>
                <div style={{marginRight: '25px', marginLeft: '20px'}}>
                    <label className="bold underlined">Modules</label>
                    {props.mode !== TenantDetailMode.update && 
                        <div style={{display: 'flex', gap: '10px', justifyContent: "end"}}>
                            <div>
                                <DropdownButton id="module-dropdown" title="Add">
                                        {renderDropdownChildren()}
                                </DropdownButton>
                            </div>
                        </div>
                    }
                    <div>
                        <Box sx={{ height: 450, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.moduleData.module.id}
                                rows={applications}
                                columns={columns}
                                initialState={{
                                pagination: {
                                    paginationModel: {
                                    pageSize: 7,
                                    },
                                },
                                }}
                                pageSizeOptions={[7]}
                                disableRowSelectionOnClick
                            />
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TenantDetail