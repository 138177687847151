import { useEffect, useRef, useState } from "react";
import { Application, Context, Field, TechnicalTemplate, Tier, Value } from "../../../types/types";
import TemplatePicker from "../../Pickers/TemplatePicker";
import { get } from "../../../util/Axios";
import DependencyTextField from "../DependencyTextField";
import { Button } from "react-bootstrap";
import { toast } from 'react-toastify';


interface TechnicalDetailProps {
    closeModal: Function,
    context: Context | undefined,
    application: Application | undefined,
    tier: Tier,
    previousPage: Function,
    updateTemplatePicker: boolean,
    templOverride?: string,
}

function TechnicalDetail(props: TechnicalDetailProps) {

    const [template, setTemplate] = useState<TechnicalTemplate | undefined>(props.application ? props.application.templateData.template : undefined)
    const [valueFields, setValueFields] = useState<Field[]>([])
    const [values, setValues] = useState<Value[]>([])

    const formRef = useRef(null);

    useEffect(() => {
        fetchFields()
    }, [template])

    const fetchFields = () => {
        if (!template || template.id.length === 0) {
            setValueFields([])
            setValues([])
            return
        }
        get(`/technical/config/context/${props.context?.id}/module/${props.application?.moduleData.module.id}/tier/${props.tier.id}/templates/${template?.id}`).then(response => {
            if (response.status !== 200) {
                toast.error(`Error fetching Fields ${response.status}: ${response.data}`)
                return;
            }
            const fields: Field[] = response.data;
            let values: Value[] = []


            fields.forEach(field => {
                values.push({name: field.name, value: field.defaultValue})
            })

            if (props.application && props.application.templateData.values.length > 0) {
                props.application!.templateData.values.forEach(appValue => {
                    const find = values.find(value => value.name === appValue.name)
                    if (!find) return;
                    find.value = appValue.value
                })
            }

            setValues(values)
            setValueFields(fields)
        }).catch(error => {
            return toast.error(`Error fetching Fields ${error.response.status}: ${error.response.data}`)
          });
    }

    const renderFields = () => {
        const fields = valueFields.map(field => {
            return (
                <DependencyTextField
                    key={`depField-${field.name}`}
                    setValue={(key: string, value: any) => {
                        const indexOf = values.indexOf(values.find((value: Value) => value.name === key)!)
                        if (indexOf > -1) {
                            const copy = values.slice()
                            copy[indexOf] = {name: key, value}
                            setValues(copy)
                        } else {
                            setValues(oldValues => [...oldValues, {name: key, value}])
                        }
                    }}
                    getValue={(key: string) => {
                        const valInValues = values.find((value: Value) => value.name === key)
                        return valInValues ? valInValues.value : ''
                    }}
                    field={field}
                    values={values}
                    type={field.type}
                    deactivated={false}
                />
            )
        })
        return (
            <div className="textFieldDiv">
                {fields}
            </div>
        )
    }

    const onNext = (e: any) => {
        e.preventDefault()
        if (!template) return

        props.application!.templateData.template = template
        props.application!.templateData.values = values
        props.application!.checked = true

        props.closeModal();
    }

    return (
        <div>
            <div className="valueFieldColumn">
                <TemplatePicker
                    context={props.context!}
                    label="Template"
                    setTemplate={setTemplate}
                    template={template}
                    update={props.updateTemplatePicker}
                    filter={{app_id: props.application?.moduleData.module.id, app_version: props.application ? props.application.moduleData.version : undefined}}
                    versionFilter={props.templOverride}
                />
            </div>
            <div>
                <form ref={formRef} onSubmit={onNext}>
                    {renderFields()}
                    <div style={{display: 'flex', gap: '10px'}}>
                        <Button onClick={() => {props.previousPage()}}>Back</Button>
                        <Button style={{marginRight: '10px'}} type="submit">Submit</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default TechnicalDetail