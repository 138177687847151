import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query"
import { del, get, post, put } from "../../util/Axios"
import { toast } from "react-toastify"
import { AppFieldsContainer, AppOfAppsTemplate, BackendConfigField, CommonConfigField, Context, DemoConfigData, Module, Role, Tag, TemplateDataResponse, Tier, User } from "../../types/types"

/* ##################################
            Fetch
################################## */ 

//#region fetch

export const useTenants = (contextId: string, refetchPeriodically?: boolean, demoOnly?: boolean) => {
    return useQuery({
        queryKey: [`tenants/${contextId}${demoOnly ? '/demo' : ''}`],
        refetchInterval: !refetchPeriodically ? false : 5000,
        queryFn: () =>
            get(`/contexts/${contextId}/tenants${demoOnly ? '?demo=true' : ''}`)
            .then((res) => res.data),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useContextLogs = (contextId: string, count: number) => {
  return useQuery({
    queryKey: [`contexts/${contextId}/logs`],
    refetchInterval: 2500,
    queryFn: () =>
        get(`/contexts/${contextId}/logs?count=${count}`)
        .then((res) => res.data),
    onError(err: any) {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
    },
  })
}

export const useModules = (contextId?: string) => {
    return useQuery({
        queryKey: [`modules`],
        queryFn: () =>
            get(`/modules${contextId ? `?context_id=${contextId}` : ``}`)
            .then((res) => res.data as Module[]),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useTiers = (contextId: string) => {
    return useQuery({
        queryKey: [`tiers/${contextId}`],
        queryFn: () =>
            get(`/contexts/${contextId}/tiers`)
            .then((res) => res.data as Tier[]),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useContexts = () => {
    return useQuery({
        queryKey: [`contexts`],
        queryFn: () =>
            get(`/contexts`)
            .then((res) => res.data as Context[]),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useRoles = () => {
    return useQuery({
        queryKey: [`roles`],
        queryFn: () =>
            get(`/roles`)
            .then((res) => {
                return res.data as Role[]
            }),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useUsers = () => {
    return useQuery({
        queryKey: [`users`],
        queryFn: () =>
            get(`/users`)
            .then((res) => {
                return res.data as User[]
            }),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useTags = () => {
  return useQuery({
      queryKey: [`tags`],
      queryFn: () =>
          get(`/tags`)
          .then((res) => {
              return res.data as Tag[]
          }),
      onError(err: any) {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
      },
    })
}

export const useCheckContext = (contextId: string) => {
    return useQuery({
        queryKey: [`${contextId}/context/check`],
        queryFn: () => {
            if (contextId.length === 0) return []
            return get(`/contexts/${contextId}/check`)
            .then((res) => {
                return res.data as string[]
            })},
        // Don't cache so that no old errors will be displayed
        cacheTime: 0,
        retry: false,
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useDetailedCommonConfig = (moduleId: string, version: string) => {
    return useQuery({
        queryKey: [`${moduleId}/${version}/common/detailed`],
        queryFn: () => {
            if (!version) return [] as CommonConfigField[]
            return get(`/defaults/${moduleId}?version=${version}&detailed=true`)
            .then((res) => {
                return res.data as CommonConfigField[]
            })},
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useCommonConfig = (moduleId: string, version: string) => {
    return useQuery({
        queryKey: [`${moduleId}/${version}/common`],
        queryFn: () => {
            if (!version) return {important: [], other: []} as AppFieldsContainer
            return get(`/defaults/${moduleId}?version=${version}`)
            .then((res) => {
                return res.data as AppFieldsContainer
            })},
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useTemplates = (contextId: string, filter?: {app_id?: string, app_version?: string}) => {
    let params = ""
    if (filter?.app_id) {
        params += `module_id=${filter.app_id}&`
    }
    if (filter?.app_version) {
        params += `version=${filter.app_version}&`
    }
    if (params.length > 0) {
        params = `?${params}`
    }
    return useQuery({
        queryKey: [`${contextId}/templates${params}`],
        queryFn: () => 
            get(`/technical/templates/${contextId}${params}`)
            .then((res) => {
                return res.data as TemplateDataResponse
            }),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useAppofAppsTemplates = () => {
    return useQuery({
        queryKey: [`aoa_template`],
        queryFn: () => 
            get(`/aoa_template`)
            .then((res) => {
                return res.data as AppOfAppsTemplate[]
            }),
        onError(err: any) {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
        },
      })
}

export const useBackendConfig = (defaultConfig: boolean, contextId: string) => {
  return useQuery({
    queryKey: [`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`],
    queryFn: () => 
        get(`/config${defaultConfig ? '/default' : `/contexts/${contextId}`}`)
        .then((res) => {
            return res.data as BackendConfigField[]
        }),
    onError(err: any) {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
    },
  })
}

export const useDemoConfig = (contextId: string) => {
  return useQuery({
    queryKey: [`demoConfig/contexts/${contextId}`],
    queryFn: () => 
        get(`/config/demo/contexts/${contextId}`)
        .then((res) => {
            return res.data as DemoConfigData
        }),
    onError(err: any) {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
    },
  })
}

//#endregion fetch

/* ##################################
            Post/Put
################################## */ 

//#region modify

export const useCreateRole = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Role, any>({
        mutationFn: (role) => {
            const contextIds: string[] = role.contexts.map(context => context.id)
         return post(`/roles/`, {context_ids: contextIds, name: role.name, context_permissions: role.context_permissions}).then(response => {
          return response
        })},
        // When mutate is called:
        onMutate: async (newRole) => {
          // Cancel any outgoing refetches
          // (so they don't overwrite our optimistic update)
          await queryClient.cancelQueries({ queryKey: ['roles'] })
      
          // Snapshot the previous value
          const previousRoles = queryClient.getQueryData(['roles'])
      
          // Optimistically update to the new value
          queryClient.setQueryData(['roles'], (old: any) => [...old, newRole])
      
          // Return a context object with the snapshotted value
          return { previousRoles }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newRole, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData(['roles'], context?.previousRoles)
        },
        onSuccess: () => toast.success("Role created!"),
        // Always refetch after error or success:
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
      })
}

export const useUpdateRole = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Role, any>({
        mutationFn: (role) => {
            const contextIds: string[] = role.contexts.map(context => context.id)
         return put(`/roles/${role.id}`, {context_ids: contextIds, name: role.name, context_permissions: role.context_permissions}).then(response => {
          return response
        })},
        onMutate: async (newRole: Role) => {
          await queryClient.cancelQueries({ queryKey: ['roles', newRole.id] })
          const previousRole = queryClient.getQueryData(['roles', newRole.id])
          queryClient.setQueryData(['roles', newRole.id], newRole)
          return { previousRole, newRole }
        },
        onError: (err, newRole, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData(['roles', context.newRole.id], context?.previousRole)
        },
        onSuccess: () => toast.success("Role edited!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
      })
}

export const useCreateContext = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Context, any>({
        mutationFn: (context) => {
         return post(`/contexts/`, {context}).then(response => {
          return response
        })},
        onMutate: async (newContext) => {
          await queryClient.cancelQueries({ queryKey: ['contexts'] })
          const previousContexts = queryClient.getQueryData(['contexts'])
          queryClient.setQueryData(['contexts'], (old: any) => [...old, newContext])
          return { previousContexts }
        },
        onError: (err, newContext, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData(['contexts'], context?.previousContexts)
        },
        onSuccess: () => toast.success("Context created!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['contexts'] })
        },
      })
}

export const useUpdateContext = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Context, any>({
        mutationFn: (context) => {
         return put(`/contexts/${context.id}`, {context}).then(response => {
          return response
        })},
        onMutate: async (newContext: Context) => {
            await queryClient.cancelQueries({ queryKey: ['contexts', newContext.id] })
            const previousContext = queryClient.getQueryData(['contexts', newContext.id])
            queryClient.setQueryData(['contexts', newContext.id], newContext)
            return { previousContext, newContext }
        },
        onError: (err, newContext, context) => {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
            queryClient.setQueryData(['contexts', context.newContext.id], context?.previousContext)
        },
        onSuccess: () => toast.success("Context edited!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['contexts'] })
        },
      })
}

export const useCreateTier = (contextId: string) => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Tier, any>({
        mutationFn: (tier) => {
         return post(`/contexts/${contextId}/tiers/`, {name: tier.name, simple: tier.simple}).then(response => {
          return response
        })},
        onMutate: async (newTier) => {
          await queryClient.cancelQueries({ queryKey: [`tiers/${contextId}`] })
          const previousTiers = queryClient.getQueryData([`tiers/${contextId}`])
          queryClient.setQueryData([`tiers/${contextId}`], (old: any) => [...old, newTier])
          return { previousTiers }
        },
        onError: (err, newTier, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData([`tiers/${contextId}`], context?.previousTiers)
        },
        onSuccess: () => toast.success("Tier created!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: [`tiers/${contextId}`] })
        },
      })
}

export const useUpdateTier = (contextId: string) => {
    const queryClient = useQueryClient()
    return useMutation<any, any, Tier, any>({
        mutationFn: (tier) => {
         return put(`/contexts/${contextId}/tiers/${tier.id}`, {name: tier.name, simple: tier.simple}).then(response => {
          return response
        })},
        onMutate: async (newTier: Tier) => {
            await queryClient.cancelQueries({ queryKey: [`tiers/${contextId}`, newTier.id] })
            const previousTier = queryClient.getQueryData([`tiers/${contextId}`, newTier.id])
            queryClient.setQueryData([`tiers/${contextId}`, newTier.id], newTier)
            return { previousTier, newTier }
        },
        onError: (err, newTier, context) => {
            toast.error(`Error ${err.response.status}: ${err.response.data}`)
            queryClient.setQueryData([`tiers/${contextId}`, context.newTier.id], context?.previousTier)
        },
        onSuccess: () => toast.success("Tier edited!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: [`tiers/${contextId}`] })
        },
      })
}

export const useUpdateTag = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, Tag, any>({
      mutationFn: (tag) => {
       return put(`/tags/${tag.id}`, {tag}).then(response => {
        return response
      })},
      onMutate: async (newTag: Tag) => {
          await queryClient.cancelQueries({ queryKey: [`tags`, newTag.id] })
          const previousTag = queryClient.getQueryData([`tags}`, newTag.id])
          queryClient.setQueryData([`tags`, newTag.id], newTag)
          return { previousTag, newTag }
      },
      onError: (err, newTag, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData([`tags`, context.newag.id], context?.previousTag)
      },
      onSuccess: () => toast.success("Tag edited!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [`tags`] })
      },
    })
}

export const useCreateTag = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, Tag, any>({
      mutationFn: (tag) => {
       return post(`/tags/`, {tag}).then(response => {
        return response
      })},
      onMutate: async (newTag) => {
        await queryClient.cancelQueries({ queryKey: [`tags`] })
        const previousTags = queryClient.getQueryData([`tags`])
        queryClient.setQueryData([`tags`], (old: any) => [...old, newTag])
        return { previousTags }
      },
      onError: (err, newTag, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
        queryClient.setQueryData([`tags`], context?.previousTags)
      },
      onSuccess: () => toast.success("Tag created!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [`tags`] })
      },
    })
}

export const useSetTenantTags = () => {
  return useMutation<any, any, {tenants: string[], tags: Tag[]}, any>({
      mutationFn: (data) => {
       return post(`/tags/set-tags`, data).then(response => {
        return response
      })},
      onError: (err, variables, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
      },
      onSuccess: () => toast.success("Tags set!"),
    })
}

export const useCreateBackendConfig = (defaultConfig: boolean, contextId: string) => {
  const queryClient = useQueryClient()
  return useMutation<any, any, BackendConfigField, any>({
      mutationFn: (field) => {
       return post(`/config/`, {name: field.name, value: field.value, default: field.default, contextId: field.contextId}).then(response => {
        return response
      })},
      onMutate: async (newTier) => {
        await queryClient.cancelQueries({ queryKey: [`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`] })
        const previousConfigs = queryClient.getQueryData([`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`])
        queryClient.setQueryData([`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`], (old: any) => [...old, newTier])
        return { previousConfigs }
      },
      onError: (err, newTier, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
        queryClient.setQueryData([`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`], context?.previousTiers)
      },
      onSuccess: () => toast.success("Config created!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [`backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`] })
      },
    })
}

export const useUpdateBackendConfig = (defaultConfig: boolean, contextId: string) => {
  const queryClient = useQueryClient()
  const queryKey = `backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`
  return useMutation<any, any, BackendConfigField, any>({
      mutationFn: (field) => {
       return put(`/config/${field.id}`, {name: field.name, value: field.value}).then(response => {
        return response
      })},
      onMutate: async (newField: BackendConfigField) => {
          await queryClient.cancelQueries({ queryKey: [queryKey, newField.id] })
          const previousConfig = queryClient.getQueryData([queryKey, newField.id])
          queryClient.setQueryData([queryKey, newField.id], newField)
          return { previousConfig, newField }
      },
      onError: (err, newField, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData([queryKey, context.newField.id], context?.previousConfig)
      },
      onSuccess: () => toast.success("Config edited!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [queryKey] })
      },
    })
}

export const useCreateDemoConfig = (contextId: string) => {
  const queryClient = useQueryClient()
  return useMutation<any, any, any, any>({
      mutationFn: (field) => {
       return post(`/config/demo`, { context_id: contextId, 
                                    versions: field.versions, 
                                    template_ids: field.template_ids, 
                                    aoa_template_id: field.aoa_template_id, 
                                    tier_id: field.tier_id,
                                    domain: field.domain,
                                    ip: field.ip,
                                    tags: field.tags
                                  }).then(response => {
        return response
      })},
      onMutate: async (newData) => {
        await queryClient.cancelQueries({ queryKey: [`demoConfig/contexts/${contextId}`] })
        const previousConfigs = queryClient.getQueryData([`demoConfig/contexts/${contextId}`])
        queryClient.setQueryData([`demoConfig/contexts/${contextId}`], (old: any) => newData)
        return { previousConfigs }
      },
      onError: (err, newData, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
        queryClient.setQueryData([`demoConfig/contexts/${contextId}`], context?.previousTiers)
      },
      onSuccess: () => toast.success("Config created!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [`demoConfig/contexts/${contextId}`] })
      },
    })
}

//#endregion modify

/* ##################################
            Delete
################################## */ 

//#region delete

export const useDeleteRole = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, string, any>({
        mutationFn: (roleId) => {
         return del(`/roles/${roleId}`).then(response => {
          return response
        })},
        onMutate: async (roleId) => {
          await queryClient.cancelQueries({ queryKey: ['roles'] })
          const previousRoles = queryClient.getQueryData(['roles'])
          queryClient.setQueryData(['roles'], (old: any) => old.filter((r: Role) => r.id !== roleId))
          return { previousRoles }
        },
        onError: (err, roleId, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData(['roles'], context?.previousRoles)
        },
        onSuccess: () => toast.success("Role deleted!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['roles'] })
        },
      })
}

export const useDeleteContext = () => {
    const queryClient = useQueryClient()
    return useMutation<any, any, string, any>({
        mutationFn: (contextId) => {
         return del(`/contexts/${contextId}`).then(response => {
          return response
        })},
        onMutate: async (contextId) => {
          await queryClient.cancelQueries({ queryKey: ['contexts'] })
          const previousContexts = queryClient.getQueryData(['contexts'])
          queryClient.setQueryData(['contexts'], (old: any) => old.filter((r: Context) => r.id !== contextId))
          return { previousContexts }
        },
        onError: (err, contextId, context) => {
          toast.error(`Error ${err.response.status}: ${err.response.data}`)
          queryClient.setQueryData(['contexts'], context?.previousContexts)
        },
        onSuccess: () => toast.success("Context deleted!"),
        onSettled: () => {
          queryClient.invalidateQueries({ queryKey: ['contexts'] })
        },
      })
}

export const useDeleteTag = () => {
  const queryClient = useQueryClient()
  return useMutation<any, any, string, any>({
      mutationFn: (tagId) => {
       return del(`/tags/${tagId}`).then(response => {
        return response
      })},
      onMutate: async (tagId) => {
        await queryClient.cancelQueries({ queryKey: ['tags'] })
        const previousTags = queryClient.getQueryData(['tags'])
        queryClient.setQueryData(['tags'], (old: any) => old.filter((r: Tag) => r.id !== tagId))
        return { previousTags }
      },
      onError: (err, tagId, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
        queryClient.setQueryData(['tags'], context?.previousTags)
      },
      onSuccess: () => toast.success("Tag deleted!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ['tags'] })
      },
    })
}

export const useDeleteBackendConfig = (defaultConfig: boolean, contextId: string) => {
  const queryKey = `backendConfig/${defaultConfig ? 'default' : `contexts/${contextId}`}`
  const queryClient = useQueryClient()
  return useMutation<any, any, string, any>({
      mutationFn: (configId) => {
       return del(`/config/${defaultConfig ? `default` : `contexts/${contextId}`}/${configId}`).then(response => {
        return response
      })},
      onMutate: async (configId) => {
        await queryClient.cancelQueries({ queryKey: [queryKey] })
        const previousConfigs = queryClient.getQueryData([queryKey])
        queryClient.setQueryData([queryKey], (old: any) => old.filter((r: Tag) => r.id !== configId))
        return { previousConfigs }
      },
      onError: (err, configId, context) => {
        toast.error(`Error ${err.response.status}: ${err.response.data}`)
        queryClient.setQueryData([queryKey], context?.previousConfigs)
      },
      onSuccess: () => toast.success("Config deleted!"),
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: [queryKey] })
      },
    })
}

//#endregion delete