import { useEffect, useState } from "react"
import { Box, Modal } from "@mui/material";
import { Editor } from "@monaco-editor/react";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import { post } from "../../util/Axios";
import { Context, Tag, Tier } from "../../types/types";
import TagMultiSelect from "../Pickers/TagMultiSelect";
import VersionPicker from "../Pickers/VersionPicker";
import TierPicker from "../Pickers/TierPicker";

export interface TemplatePreviewProps {
    module_id: string,
    template_id: string,
    app_of_apps_preview: boolean,
    context?: Context,
    open: boolean,
    cancel: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflow: 'hidden',
    p: 4,
};

function GenericTemplatePreviewModal(props: TemplatePreviewProps) {

    const [editorValue, setEditorValue] = useState("")
    const [moduleVersion, setModuleVersion] = useState("")
    const [tags, setTags] = useState<Tag[]>([])
    const [selectedTier, setSelectedTier] = useState<Tier>()

    useEffect(() => {
        if (props.open) changeEditorValue()
    }, [props.open, moduleVersion, tags, selectedTier])

    const changeEditorValue = () => {
        if (props.app_of_apps_preview) {
            const modules = [{name: 'placeholder-module', version: '1.0.0-placeholder'}]
            post(`/aoa_template/${props.template_id}/preview`, {modules, tenant_short_name: "placeholder-name"}).then(response => {
                if (response.status !== 200) {
                    return toast.error(`Something went wrong getting rendered Template... ${response.status}: ${response.data}`)
                }
                setEditorValue(response.data)
            }).catch(error => {
                toast.error(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
                setEditorValue(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
              })
        } else {
            if (!moduleVersion || moduleVersion.length === 0) return;
            const body = {
                use_placeholder_values: true,
                module_version: moduleVersion,
                tier_id: selectedTier?.id,
                tenant_short_name: "placeholder-name",
                tags: tags.map(tag => tag.name),
                domain: "placeholder-domain.de",
                db_host: "PLACERHOLDER_HOST",
                db_name: "PLACEHOLDER_NAME",
                module_version_tag: "1.0.0-PLACEHOLDER"
            }
            post(`/technical/templates/${props.template_id}/preview`, body).then(response => {
                if (response.status !== 200) {
                    return toast.error(`Something went wrong getting rendered Template... ${response.status}: ${response.data}`)
                }
                setEditorValue(response.data)
            }).catch(error => {
                toast.error(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
                setEditorValue(`Error rendering Template - ${error.response.status}: ${error.response.data}`)
              })
        }
    }

    return (
        <Modal
            open={props.open}
            onClose={() => {props.cancel()}}
        >
            <Box sx={style}>
                <span>Please keep in mind that you have to save the template, if you made changes before opening the preview!</span>
                {!props.app_of_apps_preview && <div style={{marginTop: '8px', display: 'flex', flexDirection: 'row', width: '100%', gap: '8px'}}>
                    <TagMultiSelect
                        selected={tags}
                        setSelected={setTags}
                        fullHeight={true}
                        width="20%"
                    />
                    <div style={{width: '10%'}}>
                    <VersionPicker
                        module={{id: props.module_id, name: '', required: false, prefix: '', suffix: '', tags: []}}
                        editable={true}
                        useLatest={moduleVersion === ''}
                        selectedVersion={moduleVersion}
                        setSelectedVersion={setModuleVersion}
                    />
                    </div>
                    
                    <TierPicker
                        context={props.context!}
                        label="Tier"
                        setTier={setSelectedTier}
                        tier={selectedTier}
                    />
                    
                </div>}
                <div style={{marginTop: '8px'}}>
                    <Editor
                        height={'60vh'}
                        value={editorValue}
                        options={{readOnly: true}}
                        theme={document.body.classList.contains("dark") ? "vs-dark" : "light"}
                    />
                </div> 
                <div style={{marginTop: '8px'}}>
                    <Button onClick={() => {props.cancel()}}>Close</Button>
                </div>
            </Box>
        </Modal>
    )
}

export default GenericTemplatePreviewModal