import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material"
import { useEffect, useState } from "react"
import { Button } from "react-bootstrap"
import { toast } from "react-toastify"
import { del, get, post } from "../../../util/Axios"
import { Context } from "../../../types/types"

export interface TenantUpdatePopupProps {
  selectedRows: any[]
  open: boolean
  cancel: Function
  context: Context
  onSuccess: Function
}

function TenantUpdateModal(props: TenantUpdatePopupProps) {
  const [selectedDBType, setSelectedDBType] = useState<string | undefined>(
    undefined
  )
  const [selectedDBTier, setSelectedDBTier] = useState<string | undefined>(
    undefined
  )
  const [isDemo, setIsDemo] = useState(false)
  const [availableDBInfo, setAvailableDBInfo] = useState<{
    [dbType: string]: string[]
  }>({})

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    minHeight: "20%",
    maxHeight: "75%",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    overflow: "hidden",
    p: 4,
  }

  useEffect(() => {
    const all_demo = props.selectedRows.every(
      (tenant) => tenant.is_demo === true
    )
    setIsDemo(all_demo)
  }, [props.selectedRows])

  useEffect(() => {
    fetchDBTiers()
  }, [props.context])

  useEffect(() => {
    if (props.open) return
  }, [props.open])

  useEffect(() => {
    if (!selectedDBType) return
    setSelectedDBTier(availableDBInfo[selectedDBType][0])
  }, [selectedDBType])

  const fetchDBTiers = async () => {
    get(`/contexts/${props.context.id}/crud/location/westeurope/sku-list`)
      .then((response) => {
        if (response.status === 200) {
          setAvailableDBInfo(response.data)
          const firstKey = Object.keys(response.data)[0]
          setSelectedDBType(firstKey)
        } else {
          toast.error(
            `Error fetching DB Options ${response.status} ${response.data}`
          )
        }
      })
      .catch((error) => {
        console.log(error)
        toast.error(
          `Error converting tenants ${error.response.status}: ${error.response.data}`
        )
      })
  }

  const convertTenants = () => {
    const selectedTenants = props.selectedRows
    const tenantIds = selectedTenants.map((tenant) => tenant.id)
    post(`/contexts/${props.context?.id}/crud/mandantenconversion`, {
      tenant_ids: tenantIds,
      to_demo: isDemo,
      db_type: selectedDBType,
      db_tier: selectedDBTier,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Tenants converted successfully!")
        } else {
          toast.error(
            `Error converting tenants ${response.status}: ${response.data}`
          )
        }
      })
      .catch((error) => {
        toast.error(
          `Error converting tenants ${error.response.status}: ${error.response.data}`
        )
      })
    props.onSuccess()
  }

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.cancel()
      }}
    >
      <Box sx={style}>
        <span>
          You are about to convert {props.selectedRows.length} Tenant(s)
          <br />
          Below you can select which DB Tier to change the Tenants to and
          whether or not you want them to have a Demo Tag
        </span>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Select
            className=""
            value={selectedDBType}
            label={`Database Type`}
            onChange={(event) => setSelectedDBType(event.target.value)}
          >
            {Object.keys(availableDBInfo).map((dbType) => {
              return (
                <MenuItem key={dbType} value={dbType}>
                  {dbType}
                </MenuItem>
              )
            })}
          </Select>

          <Select
            className=""
            value={selectedDBTier}
            label={`Database Tier`}
            onChange={(event) => setSelectedDBTier(event.target.value)}
          >
            {(availableDBInfo[selectedDBType || 0] || []).map((dbTier) => {
              return (
                <MenuItem key={dbTier} value={dbTier}>
                  {dbTier}
                </MenuItem>
              )
            })}
          </Select>
          <FormControlLabel
            label="Demo?"
            control={
              <Checkbox
                value={isDemo}
                onChange={(e) => setIsDemo(e.target.checked)}
              />
            }
          />
        </div>
        <div style={{ display: "flex", gap: "10px" }}>
          <Button variant="secondary" onClick={() => props.cancel()}>
            Cancel
          </Button>
          <Button onClick={() => convertTenants()}>Convert</Button>
        </div>
      </Box>
    </Modal>
  )
}

export default TenantUpdateModal
