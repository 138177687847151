import { useEffect, useState } from "react"
import { Context, Module, ModuleVersionTag, User } from "../../../types/types"
import { del, post, put } from "../../../util/Axios"
import { Box } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { Button } from "react-bootstrap"
import ModuleEditModal from "./ModuleEditModal"
import { useModules } from "../../Hooks/Hooks"
import { toast } from 'react-toastify';

interface ModuleEditorProps {
    context: Context
}

function ModuleEditor(props: ModuleEditorProps) {

    const { data: fields, refetch } = useModules(props.context.id)

    const [modalOpen, setOpenModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState<Module[]>([])
    const [editField, setFieldToEdit] = useState<Module | undefined>(undefined)

    useEffect(() => {
        refetch()
        setSelectedRows([])
    }, [props.context])

    const columns = [
        { 
          field: 'id',
          headerName: 'ID',
          width: 50
        },
        {
            field: 'name',
            headerName: "Name",
            width: 150,
        },
        {
            field: 'required',
            headerName: "Required?",
            width: 200,
        },
        {
            field: 'prefix',
            headerName: "URL-Prefix",
            width: 150,
        },
        {
            field: 'suffix',
            headerName: "URL-Suffix",
            width: 150,
        },
        {
            field: 'tags',
            headerName: 'Tags (Versions)',
            flex: 1,
            valueGetter: (value: any) => {
                return value.row?.tags.map((entry: ModuleVersionTag) => entry.version).join(", ")
            }
        }
      ];

    const openModal = () => {
        if (selectedRows.length === 1) setFieldToEdit(selectedRows[0])
        setOpenModal(true)
    }

    const deleteValues = () => {
        selectedRows.forEach((field: Module) => {
            del(`/modules/${field.id}`).then(response => {
                if (response.status === 200) {
                    refetch()
                    toast.success("Module deleted successfully")
                } else {
                    toast.error(`Error deleting Module ${response.status}: ${response.data}`)
                }
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        })
    }

    const saveField = (field: Module, createNew?: boolean) => {
        if (createNew) {
            post(`/modules/`, {name: field.name, required: field.required, prefix: field.prefix, suffix: field.suffix, tags: field.tags, context_id: props.context.id}).then(response => {
                if (response.status !== 201) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    return;
                }
                const fieldId = response.data;
                field.id = fieldId
                refetch()
                toast.success("Successfully created Module!")
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        } else {
            console.log(field)
            put(`/modules/${field.id}`, {name: field.name, required: field.required, prefix: field.prefix, suffix: field.suffix, tags: field.tags, context_id: props.context.id}).then(response => {
                if (response.status !== 200) {
                    toast.error(`Something went wrong... Code ${response.status}: ${response.data}`)
                    setFieldToEdit(undefined)
                    return;
                }

                editField!.name = field.name
                editField!.prefix = field.prefix
                editField!.suffix = field.suffix
                editField!.required = field.required
                editField!.tags = field.tags
                setFieldToEdit(undefined)
                toast.success("Successfully edited Module!")
            }).catch(error => {
                toast.error(`Error ${error.response.status}: ${error.response.data}`)
            })
        }
    }

    return (
        <div>
            <ModuleEditModal
                open={modalOpen}
                cancel={() => {
                    setFieldToEdit(undefined)
                    setOpenModal(false)
                }}
                submit={(name: string, required: boolean, prefix: string, suffix: string, tags: ModuleVersionTag[] ) => {
                    const field: Module = {id: '', name, required, prefix, suffix, tags}
                    console.log(field)
                    if (!editField) {
                        saveField(field, true)
                    } else {
                        field.id = editField.id
                        saveField(field)
                    }
                    setOpenModal(false)
                }}
                field={editField}
            />
            <div style={{display: 'flex', gap: '10px', marginBottom: '10px'}}>
                <Button onClick={() => {openModal()}}>{selectedRows.length === 1 ? "Edit" : "Create"}</Button>
                <Button disabled={selectedRows.length === 0} onClick={deleteValues} >Delete</Button>
            </div>
            <div>
                <Box sx={{ height: 550, width: '100%' }}>
                    <DataGrid
                        rows={fields ?? []}
                        columns={columns}
                        initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 10,
                            },
                        },
                        }}
                        onRowSelectionModelChange={(ids: any) => {
                            const selectedIDs = new Set(ids);
                            const selectedRowData: any = fields?.filter((row: any) =>
                                selectedIDs.has(row.id)
                            );
                            setSelectedRows(selectedRowData);
                        }}
                        rowSelectionModel={selectedRows.map(e => e.id)}
                        pageSizeOptions={[10]}
                        checkboxSelection
                        disableRowSelectionOnClick
                    />
                </Box>
            </div>
        </div>
    )

}

export default ModuleEditor