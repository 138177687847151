import { Box, Modal } from "@mui/material";
import { Context } from "../../../types/types"
import { Button } from "react-bootstrap";
import Colorful from '@uiw/react-color-colorful';
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify"

export interface ContextEditModalProps {
    field: Context | undefined,
    open: boolean,
    cancel: Function,
    submit: Function
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '35%',
    maxWidth: '75%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowX: 'hidden',
    overflowY: 'scroll',
    p: 4,
};

function ContextEditModal(props: ContextEditModalProps) {

    const formRef = useRef(null)
    const [name, setName] = useState("")
    const [urls, setUrls] = useState<string[]>([""])
    const [dbResGroup, setDbResGroup] = useState("")
    const [miscResGroup, setMiscResGroup] = useState("")
    const [repoUrl, setRepoUrl] = useState("")
    const [git_folder_path, setGitFolderPath] = useState("")
    const [ips, setIps] = useState<string[]>([""])
    const [color, setColor] = useState("")
    const [showColorPicker, setShowColorPicker] = useState(false)


    useEffect(() => {
        if (!props.open) {
            setName("")
            setUrls([""])
            setDbResGroup("")
            setMiscResGroup("")
            setRepoUrl("")
            setGitFolderPath("")
            setIps([""])
            setColor("")
            return;
        }

        if (!props.field) return;

        setName(props.field.name)
        setUrls(props.field.urls)
        setIps(props.field.ips)
        setDbResGroup(props.field.db_ressource_group_name)
        setMiscResGroup(props.field.misc_ressource_group_name)
        setRepoUrl(props.field.repoUrl)
        setGitFolderPath(props.field.git_folder_path)
        setColor(props.field.color)
    }, [props.open])

    const onSubmit = (event: any) => {
        event.preventDefault();
        const hexColorRegex = new RegExp(/^#(?:[0-9a-fA-F]{3}){1,2}$/)
        if (!hexColorRegex.test(color)) {
            return toast.error("Color seems to be invalid!");
        }
        props.submit(name,
                     urls,
                     color,
                     dbResGroup, 
                     miscResGroup, 
                     repoUrl, 
                     git_folder_path,
                     ips)
    }

    const renderDomainFields = () => {
        let index = 0
        return urls.map(url => {
            const id = index++
            return (
                <div style={{display: 'flex', gap: '10px'}} key={`url-${id}`}>
                    <input
                        type="text"
                        placeholder=""
                        value={urls[id]}
                        required={true}
                        onChange={(e) => {
                            const urlCopy = JSON.parse(JSON.stringify(urls))
                            urlCopy[id] = e.target.value
                            setUrls(urlCopy)
                        }}
                    />
                    <div onClick={() => {
                        if (urls.length > 1) 
                            removeDomain(id)
                    }}>
                        <label style={urls.length > 1 ? {cursor: 'pointer', fontSize: '25px'} : {fontSize: '25px'}}>-</label>
                    </div>
                </div>
            )
        })
    }

    const removeDomain = (index: number) => {
        const urlCopy: string[] = JSON.parse(JSON.stringify(urls))
        urlCopy.splice(index, 1)
        setUrls(urlCopy)
    }

    const createNewDomainField = () => {
        const urlCopy: string[] = JSON.parse(JSON.stringify(urls))
        urlCopy.push("")
        setUrls(urlCopy)
    }
    const renderIpFields = () => {
        let index = 0
        return ips.map(ip => {
            const id = index++
            return (
                <div style={{display: 'flex', gap: '10px'}} key={id}>
                    <input
                        type="text"
                        placeholder=""
                        value={ips[id]}
                        required={true}
                        onChange={(e) => {
                            const ipsCopy = JSON.parse(JSON.stringify(ips))
                            ipsCopy[id] = e.target.value
                            setIps(ipsCopy)
                        }}
                    />
                    <div onClick={() => {
                        if (ips.length > 1)
                            removeIp(id)
                    }}>
                        <label style={ips.length > 1 ? {cursor: 'pointer', fontSize: '25px'} : {fontSize: '25px'}}>-</label>
                    </div>
                </div>
            )
        })
    }
    const removeIp = (index: number) => {
        const ipsCopy: string[] = JSON.parse(JSON.stringify(ips))
        ipsCopy.splice(index, 1)
        setIps(ipsCopy)
    }

    const createNewIpField = () => {
        const ipsCopy: string[] = JSON.parse(JSON.stringify(ips))
        ipsCopy.push("")
        setIps(ipsCopy)
    }
    return (
        <div>
            <Modal
                open={props.open}
                onClose={() => {props.cancel()}}
            >
                <Box sx={style}>
                    <div>
                        <Button style={{marginBottom: '10px'}} onClick={() => {props.cancel()}}>Cancel</Button>
                        <form ref={formRef} onSubmit={onSubmit}>
                            <div style={{display: 'flex', flexDirection: 'column', gap: '15px'}}>
                                <div className="textFieldDiv">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={name}
                                        required={true}
                                        onChange={(e) => {setName(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <label>Domain URLs</label>
                                        <label onClick={() => {
                                            createNewDomainField()
                                        }} style={{cursor: 'pointer', fontSize: '20px', border: '1px solid', borderRadius: '200px'}}>+</label>
                                    </div>
                                    {renderDomainFields()}
                                </div>
                                <div className="textFieldDiv">
                                    <div style={{display: 'flex', gap: '10px'}}>
                                        <label>IPs</label>
                                        <label onClick={() => {
                                            createNewIpField()
                                        }} style={{cursor: 'pointer', fontSize: '20px', border: '1px solid', borderRadius: '200px'}}>+</label>
                                    </div>
                                    {renderIpFields()}
                                </div>
                                <div className="textFieldDiv">
                                    <label>DB Resource Group Name</label>
                                    <input
                                        type="text"
                                        placeholder="DB Resource Group"
                                        value={dbResGroup}
                                        required={true}
                                        onChange={(e) => {setDbResGroup(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Misc Resource Group Name</label>
                                    <input
                                        type="text"
                                        placeholder="Misc Resource Group"
                                        value={miscResGroup}
                                        required={true}
                                        onChange={(e) => {setMiscResGroup(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Repo URL</label>
                                    <input
                                        type="text"
                                        placeholder="Repo Url"
                                        value={repoUrl}
                                        required={true}
                                        onChange={(e) => {setRepoUrl(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Git Folder Path</label>
                                    <input
                                        type="text"
                                        placeholder="Git Folder Path"
                                        value={git_folder_path}
                                        required={true}
                                        onChange={(e) => {setGitFolderPath(e.target.value)}}
                                    />
                                </div>
                                <div className="textFieldDiv">
                                    <label>Color</label>
                                    <input
                                        type="text"
                                        placeholder="Color"
                                        value={color}
                                        required={true}
                                        onChange={(e) => {setColor(e.target.value)}}
                                        onFocus={() => {
                                            setShowColorPicker(true)
                                        }}
                                        onBlur={() => {
                                            setShowColorPicker(false)
                                        }}
                                    />
                                    {showColorPicker && <Colorful
                                        color={color}
                                        onChange={(e) => {
                                            setColor(e.hex)
                                        }}
                                        disableAlpha={true}
                                    />}
                                </div>
                                <div>
                                    <Button type='submit'>Save</Button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ContextEditModal